import React from "react"
import Layout from "../components/Layout/Layout"
import LayoutStyles from "../components/LayoutStyles/LayoutStyles"
import StyledBackgroundImageFull from "../components/BackgroundImageFull/BackgroundImageFull"
import SectionTransition from "../components/SectionTransition/SectionTransition"
import ScrollNudge from "../components/ScrollNudge/ScrollNudge"
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'

export default function Home() {
  return(
    <div>
      <Layout>
        <Helmet>
          <title>Home | Alan Henson - Freelance Full-Stack Engineer</title>
        </Helmet>
        <LayoutStyles>
          <StyledBackgroundImageFull imgName={'mountainCity'}/>
          <div className={'above-fold-container'}>
            <div className={'gradient-billboard'}>
              <div className={'typography-main'}>Alan Henson</div>
              <div className={'mat-text-container'}>
                <div className={'typography-tech'}>Technology<br/>x<br/>People</div>
              </div>
            </div>
            <SectionTransition to={'#000'} static>
              <ScrollNudge color={'#BFBAAD'}/>
            </SectionTransition>
          </div>
          <div className={'gradient-billboard-detail'}>
            <div className={'typography-intro-detail'}>
              I enjoy building excellent applications and investing in people.
            </div>
            <div className={'typography-intro-detail'}>
              I'm interested in web apps, software architecture, and leadership.
            </div>
            <div className={'typography-intro-detail'}>
              Feel free to <Link to={'contact'}><span className={'typography-link typography-link-tan'}>get in touch</span></Link>, or see <Link to={'projects'}><span className={'typography-link typography-link-tan'}>what I've done</span></Link>.
            </div>
          </div>
        </LayoutStyles>
      </Layout>
    </div>
  );
}
